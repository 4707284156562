import { Box } from '@material-ui/core';
import cx from 'classnames';
import { kebabCase, omit } from 'lodash';
import { useObserver } from 'mobx-react';
import React from 'react';
import { FB } from '..';
import { translate } from '../../../common/intl';
import { AsyncStatus } from '../../../state/types';
import Loader from '../../components/common/kendo/Loader';
import { Button } from '../../components/forms/fields-next';
import KendoDataGrid from '../../components/KendoDataGrid/KendoDataGrid';
import { DataGridItemChangeEvent, DataGridProps } from '../../components/KendoDataGrid/KendoDataGrid.types';
import { toastError } from '../../components/notifications';
import Text from '../../components/Text';
import { FormHeaderContext } from '../../documentRevision/forms/DocumentRevisionForm.container';
import { APPROVAL_MATRIX_ROLE_TABLE_NAME, ERROR_FIELD, ErrorField, Mode, MODE_FIELD, SEARCHABLE_GROUPS_FIELD } from './constants';
import createSchema from './schema';
import useStyles from './styles';
import { EditableRole, FBApprovalMatrixRolesProps, RoleEditEvent } from './types';
import useTableCriteria from './utils';
import { withFBApprovalMatrixRoles } from './wrap';

const FBApprovalMatrixRoles: React.FC<FBApprovalMatrixRolesProps> = ({
  approvalRoles = [],
  groups,
  disabled,
  addRole,
  updateRole,
}) => {
  const classes = useStyles();
  const { workspaceState } = FB.useStores();
  const [editedRole, setEditedRole] = React.useState<EditableRole>();
  const { asyncState } = React.useContext(FormHeaderContext) ?? {};

  const isLoading = useObserver(
    () => asyncState?.status === AsyncStatus.Active || workspaceState?.documentRevApi.loading || false,
  );

  const addNewRole = () => setEditedRole({
    id: '',
    name: '',
    index: approvalRoles.length + 1,
    groups: [],
    employees: [],
    [MODE_FIELD]: Mode.add,
    [SEARCHABLE_GROUPS_FIELD]: '',
  });

  const editRole = ({ dataItem }: RoleEditEvent) => setEditedRole(dataItem);

  const discardRole = () => setEditedRole(undefined);

  const setFieldError = (field: ErrorField) => setEditedRole(prev => {
    if (!prev) {
      return prev;
    }
    return {
      ...prev,
      [ERROR_FIELD]: field,
    };
  });

  const isRoleValid = (role: EditableRole) => {
    if (role.name === '') {
      toastError(translate('form.builder.approvalRoles.error.empty'));
      setFieldError(ErrorField.name);
      return false;
    }

    if (role[MODE_FIELD] === Mode.add && approvalRoles.some(({ name }) => name === role.name)) {
      toastError(translate('form.builder.approvalRoles.error.exists', { name: role.name }));
      setFieldError(ErrorField.name);
      return false;
    }
    return true;
  };

  const saveRole = () => {
    if (!editedRole || !isRoleValid(editedRole)) {
      return;
    }
    const roleToSave = omit(editedRole, MODE_FIELD, SEARCHABLE_GROUPS_FIELD);
    isNewRoleAdded ? addRole?.(roleToSave) : updateRole?.(roleToSave);

    discardRole();
  };

  const updateEditedRole = (event: DataGridItemChangeEvent<EditableRole>) => {
    if (!editedRole || !event.field) {
      return;
    }
    setEditedRole({
      ...omit(editedRole, ERROR_FIELD),
      [event.field]: event.value as unknown,
    });
  };

  const isActive = !disabled;
  const isNewRoleAdded = editedRole?.[MODE_FIELD] === Mode.add;

  const existingRoles = approvalRoles.map((role) => {
    const isRoleEdited = editedRole && editedRole.id === role.id;
    return {
      ...(isRoleEdited ? editedRole : role),
      [MODE_FIELD]: isRoleEdited ? Mode.edit : Mode.show,
      [SEARCHABLE_GROUPS_FIELD]: role.groups.map(({ name }) => name).join(' '),
    };
  });
  const roles = isNewRoleAdded ? [...existingRoles, editedRole] : existingRoles;

  const { dataState, dataStateChange } = useTableCriteria(APPROVAL_MATRIX_ROLE_TABLE_NAME);

  const schema = createSchema({
    groups,
    isLoading,
    actionsClass: classes.actionsCell,
    saveRole,
    discardRole,
    onRowClick: isActive ? editRole : undefined,
  });

  const rowRender: DataGridProps<EditableRole>['rowRender'] = (row, { dataItem }) => {
    const item = dataItem as EditableRole;
    const isUpdating = [Mode.add, Mode.edit].includes(item[MODE_FIELD]);
    const dataCy = `row-${kebabCase(item.name)}`;

    return React.cloneElement(
      row,
      {
        ['data-cy' as string]: dataCy,
        className: cx(row.props.className, { [classes.updatingRow]: isUpdating }),
      },
    );
  };

  return (
    <Box className={classes.root}>
      {isLoading && <Loader />}
      <KendoDataGrid<EditableRole>
        schema={schema}
        data={roles}
        {...dataState}
        onDataStateChange={dataStateChange}
        rowRender={rowRender}
        onItemChange={updateEditedRole}
        className={cx(classes.grid, { [classes.gridWithButton]: isActive })}
        onRowClick={isActive ? editRole : undefined}
        filterable
      />
      {isActive && (
        <Button
          attached
          fullWidth
          kind="add"
          data-cy="form-builder-add-another-role"
          disabled={editedRole !== undefined || isLoading}
          onClick={addNewRole}
        >
          <Text message="form.builder.add.another.role" />
        </Button>
      )}
    </Box>
  );
};

export default withFBApprovalMatrixRoles(FBApprovalMatrixRoles);
